<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [
        
      ],
      devicelist:[],
      devicesearch: '',
      getdeviceslist: '',
      selectdevicestatus: '-1',
      jurisdiction:[],
      ismobile:false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      loading: true,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.devicelist.length;
    }
  },
  mounted() {
      this.title= this.$t('menuitems.devices.lists')
      this.items=[
        {
          text: this.$t('menuitems.devices.text'),
          href: "/"
        },
        {
          text: this.$t('menuitems.devices.lists'),
          active: true
        }
      ]
    //   this.titleTemplate(this.$t('menuitems.devices.lists'))
      this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"))
      this.activeapp = JSON.parse(sessionStorage.getItem("apps"))
      this.userinfo = JSON.parse(localStorage.getItem('user'))
      this.getdevicelist()
      let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if(flag){
          this.ismobile = true
      }
    //   this.page.title=this.$t('menuitems.devices.lists')
  },
  methods: {
      getdevicelist() {
          var that=this
          that.$axios.post('https://admin.aicoiot.beer/api.json', {
              action: 'devicelistv2'
          },{
              headers: { "Openid": that.userinfo.openid }
          }).then(function (response) {
              that.loading=false
              that.devicelist = response.data.data
          }).catch(function (error) {
              console.log(error);
          });
          
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
  },
  computed: {
      searchindeviceslist() {
          var datas=this.devicelist
          if(this.devicesearch){
              datas=datas.filter(value => {
                  return (!this.devicesearch || value.ED_id.toLowerCase().includes(this.devicesearch.trim().toLowerCase())) ||
                        (!this.devicesearch || value.name.toLowerCase().includes(this.devicesearch.trim().toLowerCase())) ||
                        (!this.devicesearch || value.device_sn.toLowerCase().includes(this.devicesearch.trim().toLowerCase())) ||
                        (!this.devicesearch || value.version.toLowerCase().includes(this.devicesearch.trim().toLowerCase())) ||
                        (!this.devicesearch || value.user.toLowerCase().includes(this.devicesearch.trim().toLowerCase()))
              })
          }
          if(this.selectdevicestatus!=-1){
              switch(this.selectdevicestatus){
                  case 0:
                      datas=datas.filter(value => {
                          return (value.status==0)
                      })
                      break;
                  case 1:
                      datas=datas.filter(value => {
                          return (value.online==1)
                      })
                      break;
                  case 5:
                      datas=datas.filter(value => {
                          return (value.status==5)
                      })
                      break;
                  case 9:
                      datas=datas.filter(value => {
                          return (value.online==0)
                      })
                      break;
                  
              }
          }
          return datas
      }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="col-sm-12 col-md-6">
                <p>
                    <a href="javascaript:;" class="btn btn-success">
                        <i class="ri-file-add-line"></i>
                        {{ $t("device.lists.add") }}
                    </a>
                </p>
                
            </div>
            <div class="row col-md-12">
                <div class="col-md-6" style=" padding: 0; ">
                    <input type="search" v-model="devicesearch" class="form-control" :placeholder="$t('device.lists.search')">
                </div>
                <div class="col-md-3" style=" padding: 0; ">
                    <select class="form-control">
                        <option disabled="" selected="" value="-1">{{ $t('device.lists.type') }}</option>
                    </select>
                </div>
                <div class="col-md-3" style=" padding: 0; ">
                    <select class="form-control" v-model="selectdevicestatus">
                        <option value="-1" selected="">{{ $t('device.lists.status') }}</option>
                        <option value="1">{{ $t('device.lists.ok') }}</option>
                        <option value="0">{{ $t('device.lists.offline') }}</option>
                        <option value="9">{{ $t('device.lists.maintain') }}</option>
                        <option value="5">{{ $t('device.lists.overdue') }}</option>
                    </select>
                </div>
            </div>
            <div class="row col-md-12" v-if="!ismobile">
                <table id="deviceslist_table" class="table table-striped">
                    <thead>
                        <tr>
                            <th class="sortStyle">{{ $t('device.lists.id') }}</th>
                            <th class="sortStyle">{{ $t('device.lists.name') }}</th>
                            <th class="sortStyle">{{ $t('device.lists.version') }}</th>
                            <th class="sortStyle">{{ $t('device.lists.status') }}</th>
                            <th class="sortStyle">{{ $t('device.lists.username') }}</th>
                            <th class="sortStyle">{{ $t('device.lists.action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!searchindeviceslist">
                            <td colspan="7" align="center">
                                {{ $t("device.error.nodata") }}
                            </td>
                        </tr>
                        <tr v-else v-for="(val) in searchindeviceslist" :class="'device'+val.id" :key="val.id">
                            <td>{{val.ED_id}}</td>
                            <td>{{val.name}}</td>
                            <td>{{val.version}}</td>
                            <td>
                                <label class="badge badge-warning" v-if="val.status==0">{{ $t("device.lists.overdue") }}</label>
                                <label class="badge badge-danger" v-if="val.status==5">{{ $t("device.lists.maintain") }}</label>
                                <label class="badge badge-success" v-if="val.status==1 && val.online==1">{{ $t("device.lists.ok") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.online==0">{{ $t("device.lists.offline") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==31">{{ $t("device.error.error31") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==32">{{ $t("device.error.error32") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==33">{{ $t("device.error.error33") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==34">{{ $t("device.error.error34") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==35">{{ $t("device.error.error35") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==36">{{ $t("device.error.error36") }}</label>
                                <label class="badge badge-danger" v-if="val.status==1 && val.error_code==37">{{ $t("device.error.error37") }}</label>
                            </td>
                            <td>{{val.user}}</td>
                            <td>
                                <router-link :to="{ path: '/device/devicedetail',query:{ id: val.id } }">
                                    <a class="btn btn-info rounded-pill" v-if="jurisdiction.indexOf('devices-info-show')>=0">
                                        {{ $t("device.lists.info") }}
                                    </a>
                                </router-link>
                                <button type="button" class="btn btn-danger rounded-pill">{{ $t("device.lists.delete") }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row col-md-12" v-if="ismobile">
                <p></p>
                <van-collapse v-model="activeNames">
                    <van-collapse-item v-for="(val) in searchindeviceslist" :title="val.name" :key="val.id" :name="val.ED_id">
                        
                    </van-collapse-item>
                </van-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>